
body {
  margin: 0;
  font-family: Verdana,Arial, Helvetica, sans-serif !important;
  color: #000 !important;
  font-size: 8pt !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  text-align: left !important;
  background-color: #fff !important;
}

h1 {
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: calibri,Arial, Helvetica, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-input {
    background-color: #b2dbf1 !important;
    border: solid 1px #b8b8b8 !important;
    border-radius:  0px !important;
    background-position: 96% center;
    background-repeat: no-repeat;
    box-shadow: none !important;
    outline: none;
    height: 20px !important;
    font-size: 8pt !important;
    padding: 3px 3px !important;
    color: #000 !important;
}

.custom-select-input {
  background-color: #b2dbf1 !important;
    border-radius:  0px !important;
    background-position: 96% center;
    background-repeat: no-repeat;
    box-shadow: none !important;
    outline: none;
    height: 20px !important;
    font-size: 8pt  !important;
    padding: 3px 0px !important;
    border: solid 1px #b8b8b8 !important;
    color: #000 !important;
}

.header {
  background: linear-gradient(
180deg
, #D5DEDE 0%, #B9BFC0 100%);
  border: none;
}

.btn.btn-primary {
  background: linear-gradient(
180deg
, #D5DEDE 0%, #B9BFC0 100%) !important;
  border: none !important;
  border-radius: 0px !important;
  color: #000 !important;
  font-size: 8pt !important;
  box-shadow: none !important;
  width: 80px !important;
  font-family: Arial, Helvetica, sans-serif !important; 
  padding: 2px;
  border: 1px solid #9f9f9f !important;
}

.modal-dialog {
  max-width: 710px !important;
}

.modal-content {
  padding: 3px !important;
  border: 1px solid #6A7691 !important;
  border-radius: 4px !important;
}

.modal-header {
  background: #EAEAEA !important;
  border: 1px solid #6A7691 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%) !important;
  border-radius: 5px !important;
  padding: .4em 1em !important;
}

.modal-title {
  font-size: 1.1em;
  font-weight: bold !important;
  font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
}

.modal-body {
  padding: 10px 5px !important;
  height:160px !important;
}

.modal-body h3 {
  font-size: 12px !important;
  color: #000 !important;
  margin: 0 0 30px !important;
  font-weight: bold;
}

.modal-body p {
  font-size: 10px !important;
  margin-bottom: 1rem !important;
}

.close {
    float: right !important;
    font-size: 1.1rem !important;
    font-weight: bold !important;
    line-height: 1 !important;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff !important;
}

.name-select__control{
    background-color : transparent !important;
    border-color: transparent !important;
    border-width: 0px !important;
    border-radius: 0px !important;
    box-shadow: 0 0 0 0px !important;
    min-height: 20px !important;
    height: 100% !important;
}

.name-select__menu{
  margin-top: 2px !important;
}

.name-select__menu-list {
  padding-top: 0px  !important;
  padding-bottom: 0px  !important;
  border-width: 0px 1px 1px 1px;
  border-color: #b8b8b8 !important;
  border-style: solid !important;
}
.Toastify__toast-container{
  padding-top: 0px  !important;
  top: 0em !important;
}
.Toastify__toast {
  min-height: 50px !important;
    padding: 4px !important;
    border-radius: 0px 0px 5px 5px !important;
}

.Toastify__close-button {
  color: #000 !important;
}

.container{
  margin-left: inherit !important;
}

.name-select__value-container{
  position: inherit !important;
  color: #000 !important;
}

.name-select__indicator{
  padding: 0px !important;
}



.name-select__indicator-separator{
  display: none !important;
}

.name-select__indicator {
  color: hsl(0, 0%, 13%) !important;
  margin-top: -7px;
}

.name-select__value-container{
  height: 20px !important;
  margin-top: -7px;
  padding: 0px 3px !important;
}

.Toastify__toast--error {
  background: #cc0000 !important;
  font: 13px !important;
  border: 1px solid black !important;
}

.Toastify__toast-body{
  text-align: center!important;
  width: 100%!important;
  font-size: 13px;
  font-weight: 600 !important;
}
.h4, h4 {
  font-size: 1.1em !important;
}

.readonly-input {
  background-color: #d8d8d8 !important;
    border: 0px !important ;
    border-radius:  0px !important;
    background-position: 96% center;
    background-repeat: no-repeat;
    box-shadow: none !important;
    outline: none;
    height: 20px !important;
    font-size: 8pt !important;
    padding: 3px 3px !important;
    color: #000 !important;
}

.readonly-select {
    background-color: #ffff !important;
    border: solid 1px #b8b8b8 !important;
    border-radius:  0px !important;
    background-position: 96% center;
    background-repeat: no-repeat;
    box-shadow: none !important;
    outline: none;
    height: 20px !important;
    font-size: 8pt !important;
    padding: 3px 3px !important;
    color: #000 !important;
}

.col-form-label{
  height: 20px !important;
}

.name-select__group-heading {
  background-color: #b2dbf1 !important;
  color: #000 !important;
  font-size: 8pt !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}
.name-select__group{
  background-color: #b2dbf1 !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;

}